<template>
    <v-layout>
        <!-- <transition name="fade"> -->
        <v-card class="pa-2 ma-2" width="100%">
            <!-- <v-container fluid grid-list-lg> -->
            <v-layout row wrap>
                <v-flex md9 xs12>
                    <!--                    TODO: Test on Android Device. Weekly Test, Daily Test both. -->
                    <v-progress-linear :indeterminate="true" v-show="showProgress"/>

                    <v-layout class="hidden-md-and-up" v-show="!showProgress">
                        <v-flex xs12>
                            <!-- deadline="2019-07-01 00:00:00" -->
                            <flip-countdown :addminutes="min" v-if="startTimer"/>
                        </v-flex>
                    </v-layout>
                    <v-card class="black--text" flat v-show="!showProgress">
                        <v-card-title primary-title>
                            <div>
                                <div class="headline text--primary">Question {{ currentQuestionNumber }}<br>
                                </div>
                                <div>
                                  <span class="text--primary">{{ currentQuestion.q }}</span>
                                    <v-img :src="currentQuestion.i" alt="loading image" aspect-ratio="1"
                                           contain
                                           height="300px" lazy-src="https://picsum.photos/id/11/100/60"
                                           v-if="currentQuestion.i"
                                           width="250px">
                                        <template v-slot:placeholder>
                                            <v-row
                                                    align="center"
                                                    class="fill-height ma-0"
                                                    justify="center"
                                            >
                                                <v-progress-circular color="indigo lighten-5" indeterminate/>
                                            </v-row>
                                        </template>
                                    </v-img>
                                </div>
                                <v-radio-group :mandatory="false" v-model="radios">
                                    <v-radio

                                            :checked="currentQuestion.useranswered === index"
                                            :key="index"
                                            :label="option"
                                            :value="index"
                                            v-for="(option, index) in currentQuestion.o"
                                    />
                                </v-radio-group>
                            </div>
                        </v-card-title>
                        <v-card-actions>
                            <v-layout class="ma-2" row wrap>
                                <v-flex md4 sm4 xs12>
                                    <v-btn
                                            @click="saveAndNext()"
                                            block
                                            class="mt-2"
                                            color="primary"
                                            dark
                                            v-if="numberofQuestions !== currentQuestionNumber"
                                    >
                                        Save &amp; Next
                                        <v-icon dark right>mdi-check-circle</v-icon>
                                    </v-btn>
                                </v-flex>
                                <v-flex md4 sm4 xs12>
                                    <v-btn @click="markForReview()"
                                           block class="mt-2" color="orange" dark>
                                        Mark For Review
                                      <v-icon dark right>mdi-block-helper</v-icon>
                                    </v-btn>
                                </v-flex>
                                <v-flex md4 sm4 xs12>
                                    <v-btn @click="clearResponse()"
                                           block class="mt-2" outlined>
                                      <v-icon dark left>mdi-close-circle</v-icon>
                                        Clear Response
                                    </v-btn>
                                </v-flex>
                            </v-layout>

                            <!-- <v-icon large @click="next">keyboard_arrow_left</v-icon>
                            <v-spacer></v-spacer>
                            <v-icon large @click="next">keyboard_arrow_right</v-icon>-->
                        </v-card-actions>
                        <v-layout class="text-center pa2 ma2" row style="width:100%" wrap>
                            <v-flex class="" md12 sm12 xs12>
                                <v-btn @click="submitResponses()" dark>
                                  <v-icon dark left>mdi-send</v-icon>
                                    Submit Exam
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </v-card>
                </v-flex>

                <v-layout class="hidden-md-and-up">
                    <v-bottom-sheet v-model="sheet">
                        <v-sheet class="text-center">
                            <v-layout class="sibling-2" id="scrolling_div1" ref="allQuestions" row wrap>
                                <v-flex :id="`c${i}`" :key="`4${i}`" v-for="i in numberofQuestions" xs3>
                                    <!--  -->
                                    <v-btn
                                            :color="getColor(i)"
                                            class="pa-2 ma-2"
                                            fab
                                            small
                                            v-on:click.stop="showQuestionNumber(i)"
                                    >{{i}}
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                            <v-divider class="ma-3"/>

                            <v-layout row wrap>
                                <v-flex xs6>
                                    <v-card class="text-xs-center" color="red" dark>
                                        <v-card-text class="px-0">Not Attempted</v-card-text>
                                    </v-card>
                                </v-flex>
                              <v-flex xs6>
                                <v-card class="text-xs-center" color="cyan" dark>
                                  <v-card-text class="px-0">Attempted</v-card-text>
                                </v-card>
                              </v-flex>
                              <v-flex xs6>
                                <v-card class="text-xs-center" color="orange" dark>
                                  <v-card-text class="px-0">Mark for Review</v-card-text>
                                </v-card>
                              </v-flex>
                              <v-flex xs6>
                                <v-card class="text-xs-center" color="white">
                                  <v-card-text class="px-0 text--primary">Not Visited</v-card-text>
                                </v-card>
                              </v-flex>
                            </v-layout>
                        </v-sheet>
                    </v-bottom-sheet>
                </v-layout>
                <v-flex class="hidden-sm-and-down" md3>
                    <v-layout v-show="!showProgress">
                        <v-flex xs12>
                            <!-- deadline="2019-07-01 00:00:00" -->
                            <flip-countdown :addminutes="min" v-if="startTimer"/>
                        </v-flex>
                    </v-layout>
                    <v-layout class="overflow-y-auto overflow-x-hidden pa-2 ma-2" id="scrolling_div" ref="allQuestions"
                              row>
                        <v-row style="height: 42vh !important;">
                            <v-flex :id="`b${i}`" :key="`4${i}`" class="pa-2 ma-2 text-center"
                                    v-for="i in numberofQuestions" xs3>
                                <!--  -->
                                <v-btn :color="getColor(i)" :ref="`d${i}`" fab small
                                       v-on:click.stop="showQuestionNumber(i)">{{i}}
                                </v-btn>
                            </v-flex>
                        </v-row>
                    </v-layout>
                    <v-divider class="ma-3"/>

                    <v-layout class="ma-2" row wrap>
                        <v-flex xs6>
                            <v-card class="text-xs-center" color="red" dark>
                                <v-card-text class="mx-auto">Not Attempted</v-card-text>
                            </v-card>
                        </v-flex>
                      <v-flex xs6>
                        <v-card class="text-xs-center" color="cyan" dark>
                          <v-card-text class="mx-auto">Attempted</v-card-text>
                        </v-card>
                      </v-flex>
                      <v-flex xs6>
                        <v-card class="text-xs-center" color="orange" dark>
                          <v-card-text class="mx-auto">Marked for Review</v-card-text>
                        </v-card>
                      </v-flex>
                      <v-flex xs6>
                        <v-card class="text-xs-center">
                          <v-card-text class="mx-auto ">Not Visited</v-card-text>
                        </v-card>
                      </v-flex>
                    </v-layout>
                </v-flex>
            </v-layout>
            <!-- </v-container> -->
        </v-card>

        <v-dialog align-center justify-center max-width="290" persistent v-model="dialog">
            <v-card>
                <v-card-title
                        class="title"
                        v-if="overlay"
                >Your responses are being sent to server. Please wait.
                </v-card-title>
                <v-card-title class="title" v-else>Responses sent successfully.</v-card-title>
                <v-layout v-if="overlay">
                    <v-row align="center" justify="center">
                        <v-progress-circular color="primary" indeterminate/>
                    </v-row>
                </v-layout>
                <v-card-text>You can check the results now or later by going to Results in the left menu</v-card-text>
                <v-card-actions>
                    <v-spacer/>


                    <v-btn @click="goHome()" color="red darken-1" dark v-if="!overlay">Exit</v-btn>
                    <v-btn @click="goResults()" color="green darken-1" dark v-if="!overlay">Show Me Now</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-layout class="text-center">
            <v-dialog max-width="290" persistent v-model="timeUpDialog">
                <v-card>
                  <v-card-title class="headline">Time's Up</v-card-title>
                  <v-card-text>Click the Submit Button Below to submit the test.</v-card-text>
                  <v-card-actions>
                    <v-spacer/>
                    <v-btn @click="timeUpSubmitResponses()" color="green--text" text>Submit</v-btn>
                  </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>

      <v-btn absolute class="hidden-md-and-up mt-8" color="pink" dark fab right top @click="sheet= !sheet">
        <v-icon v-if="sheet">mdi-close</v-icon>
        <v-icon v-else>mdi-dots-vertical</v-icon>
      </v-btn>
      <!-- </transition> -->
    </v-layout>
</template>


<script>
import FlipCountdown from "../components/FlipCountdown";
// import FlipCountdown from 'vue2-flip-countdown'
import {APIService} from "../APIService";
import axios from "axios";

const apiService = new APIService();

export default {
  metaInfo: {
    title: 'Test',
    titleTemplate: '%s - Dental Pockets ||| Mastering Dentisty the Easy Way!',
    htmlAttrs: {
      lang: 'en',
      amp: true
    },
    meta: [
      {httpEquiv: 'Content-Type', content: 'text/html; charset=utf-8'},
      {
        name: 'description',
        content: "NEET, NBDE, Dental Exams, PGI, JIPMER, DHA, preparation of dental exams now easy. Image based questions are now made easy."
      }
    ]
  },
  components: {FlipCountdown},
        data: () => ({
            radios: null,
            drawers: true,
            numberofQuestions: 0,
            testQuestions: [],
            overlay: false,
            dialog: false,
            startTimer: false,
            sheet: false,
            showProgress: true,
            timeUpDialog: false,
            isItDailyTest: false,
            isExamSubmitted: false,
        }),
        props: {
            source: String
        },
        created() {
            this.$store.commit("timeUP", false);
        },
        mounted: function () {
            this.$nextTick(function () {
                // Code that will run only after the
                // entire view has been rendered

                let path = this.$router.currentRoute.name;
                // eslint-disable-next-line no-console
                // console.log(path);
                let isDailyPresent = path.includes("startDailyTest");
                if (isDailyPresent) {
                    // eslint-disable-next-line no-console
                    // console.log(this.$route.params.id);
                    this.isItDailyTest = true;
                    this.getDailyTestQuestionsFromServer(this.$route.params.id);

                } else if (path.includes("startWeeklyTest")) {

                    this.getWeeklyTestQuestionsFromServer(this.$route.params.id);
                    this.isItDailyTest = false;

                } else {
                    // this.getQuestionsFromServer(this.$route.params.id);
                }

            });
        },
        computed: {
            timeUp() {
                return this.$store.getters.getTimeUp;
            },
            realQuestionNumber() {
                return this.$store.getters.getCurrentQuestionNumber - 1;
            },
            currentQuestionNumber() {
                return this.$store.getters.getCurrentQuestionNumber;
            },
            currentQuestion() {
                const app = this;
                if (this.testQuestions.length === 0) {
                    return 0;
                }
                let cq = this.testQuestions[this.realQuestionNumber];
                if (cq["useranswered"] === undefined || cq["useranswered"] === "") {
                    app.radios = null;
                } else {
                    app.radios = cq["useranswered"];
                }
                return cq;
            }
        },

        beforeDestroy: function () {
            this.$store.commit("setQuestionNumber", 1);
            this.testQuestions = [];
            this.startTimer = false;
            this.radios = null
        },
        watch: {
            // eslint-disable-next-line no-unused-vars
            sheet(newC, oldC) {


                if (newC) {
                    let maxLimit = this.currentQuestionNumber + 1;
                    let refID = "c" + maxLimit;
                    let divid = 'scrolling_div1';
                    let padding = 90;
                    this.scrollShortcuts(refID, divid, padding);

                }
            },
            // eslint-disable-next-line no-unused-vars
            timeUp(newCount, oldCount) {
                if (newCount) {
                    this.timeUpDialog = true;
                    // eslint-disable-next-line no-console
                    console.log("Time up is true");
                }
            },
            // eslint-disable-next-line no-unused-vars
            currentQuestionNumber(newCount, oldCount) {
                // Our fancy notification (2).
                // console.log(`We have ${newCount} fruits now, yaay!`);
                this.showThisQuestion();
            },
            // eslint-disable-next-line no-unused-vars
            radios(newCount, oldCount) {
                if (newCount != null) {
                    // console.log(`We have ${newCount} banana now, yaay!`);

                    this.testQuestions[this.currentQuestionNumber - 1][
                        "useranswered"
                        ] = newCount;
                    this.testQuestions[this.currentQuestionNumber - 1]["status"] = 2;
                }
            }
        },
        methods: {
            next() {
            },
            showQuestionNumber(i) {
                // let messageDisplay = this.$refs.allQuestions;
                // this.scrollPosition = messageDisplay.scrollTop;
                // console.log("SP: "+);
                // messageDisplay.scrollTop = messageDisplay.scrollHeight;
                // console.log(i);
                this.$store.commit("setQuestionNumber", i);
                if (
                    this.testQuestions[i - 1]["useranswered"] === undefined ||
                    this.testQuestions[i - 1]["useranswered"] === ""
                ) {
                    this.radios = this.testQuestions[i - 1]["useranswered"];
                }
                if (
                    this.testQuestions[i - 1]["status"] === undefined ||
                    this.testQuestions[i - 1]["status"] === ""
                ) {
                    this.testQuestions[i - 1]["status"] = 1;
                    this.radios = null;
                }
            },
            getColor(d) {

                // console.log(d);
                if (this.testQuestions.length === 0) {
                    return "blue-grey lighten-2";
                }
                // if (this.testQuestions.length == d) {
                // }

                let s = d - 1;
                // console.log(
                //   "Status of " + s + " is " + this.testQuestions[s][["status"]]
                // );

                // var realQuestionNumber = app.currentQuestionNumber - 1;
                // console.log(d + "  -  " + s);
                if (this.testQuestions[s]["status"] === undefined) {
                    // Unseen
                    // undefined unseen, 1 not attempted, 2 attempted, 3 mark for review

                    return "";
                } else if (this.testQuestions[s]["status"] === 0) {
                    return "red";
                } else if (this.testQuestions[s]["status"] === 2) {
                    // attempted
                    return "cyan";
                } else if (this.testQuestions[s]["status"] === 3) {
                    // mark for review
                    return "orange";
                } else if (this.testQuestions[s]["status"] === 1) {
                    // unanswered
                    // console.log("returning red accent-4");
                    return "red accent-4";
                }
            },


            showThisQuestion() {
                if (this.testQuestions[this.realQuestionNumber] !== undefined) {
                    // console.log("NOT UNDEFINED");
                    // var s = this.currentQuestion;
                    this.testQuestions[this.realQuestionNumber]["status"] = 1;
                    // console.log(
                    // JSON.stringify(this.testQuestions[this.realQuestionNumber])
                    // );
                    // var co = this.getColor(this.realQuestionNumber);
                    // console.log(co);
                } else {
                    // console.log("UNDEFINED");
                }

                //  var messageDisplay = this.$refs.allQuestions;
                //   messageDisplay.scrollTop = this.scrollPosition;
                //   console.log("Scroll Position SEt at: " + this.scrollPosition);
            },
            scrollShortcuts(refID, divid, topMarginAndSizePadding) {
                this.$nextTick(function () {

                    const scrollDiv = document.getElementById(divid);
                    let divTop = scrollDiv.offsetTop;

                    const myElement = document.getElementById(refID);
                    let topPos = myElement.offsetTop;


                    document.getElementById(divid).scrollTop = topPos - divTop - topMarginAndSizePadding;
                });
            },
            saveAndNext() {
                let maxLimit = this.currentQuestionNumber + 1;
                let refID = "b" + maxLimit;
                let divid = 'scrolling_div';
                let padding = 60;
                this.scrollShortcuts(refID, divid, padding);


                // console.log(maxLimit);
                if (this.numberofQuestions >= maxLimit) {
                    // console.log("Showing Next Question");
                    this.showQuestionNumber(maxLimit);
                }
                window.scrollTo({top: 0, behavior: 'smooth'});
            },
            markForReview() {
                if (this.radios == null) {
                    this.radios = 1;
                    this.radios = null;
                } else {
                    let rad = this.radios;
                    this.radios = null;
                    this.radios = rad;
                }
                if (this.testQuestions[this.realQuestionNumber]["status"] === 3) {
                    if (this.radios == null) {
                        this.testQuestions[this.realQuestionNumber]["status"] = 1;
                    } else {
                        this.testQuestions[this.realQuestionNumber]["status"] = 2;
                    }
                } else {
                    this.testQuestions[this.realQuestionNumber]["status"] = 3;
                }
            },
            timeUpSubmitResponses() {

                if (this.isItDailyTest) {
                    this.sendDailyTestScoreToServer();
                } else {
                    this.sendWeeklyTestScoreToServer();
                }
            },
            submitResponses() {
                if (
                    confirm(
                        "Are you sure you want to submit the test? This cannot be undone."
                    )
                ) {

                    if (this.isItDailyTest) {
                        this.sendDailyTestScoreToServer();
                    } else {
                        this.sendWeeklyTestScoreToServer();
                        // Save it!
                        // this.sendTestToServer();
                    }

                } else {
                    // Do nothing!

                }
            },
            sendWeeklyTestScoreToServer() {
                const app = this;

                app.overlay = true;
                app.dialog = true;
                app.startTimer = false;

                let arr = [];
                this.testQuestions.forEach(element => {
                    let ua = element.useranswered;
                    let st = element.status;
                    let obj = {};
                    if (ua >= 0) {
                        obj["ua"] = ua;
                        obj["st"] = st;
                        arr.push(obj);
                    } else {
                        obj["ua"] = 999;
                        if (st === undefined) {
                            obj["st"] = "";
                        } else {
                            obj["st"] = st;
                        }
                        arr.push(obj);
                    }
                });

                let testID = this.$route.params.id;
                apiService.sendWeeklyTestScoreToServer(testID, arr).then(data => {
                    // eslint-disable-next-line no-console
                    // console.log(data.status);
                    if (data.status === 200) {
                        app.overlay = false;
                        app.isExamSubmitted = true;
                    }

                });
            },
            sendDailyTestScoreToServer() {
                const app = this;

                app.overlay = true;
                app.dialog = true;
                app.startTimer = false;

                let arr = [];
                this.testQuestions.forEach(element => {
                    let ua = element.useranswered;
                    let st = element.status;
                    let obj = {};
                    if (ua >= 0) {
                        obj["ua"] = ua;
                        obj["st"] = st;
                        arr.push(obj);
                    } else {
                        obj["ua"] = 999;
                        if (st === undefined) {
                            obj["st"] = "";
                        } else {
                            obj["st"] = st;
                        }
                        arr.push(obj);
                    }
                });

                let testID = this.$route.params.id;
                apiService.sendDailyTestScoreToServer(testID, arr).then(data => {
                    // eslint-disable-next-line no-console
                    console.log(data.status);
                    if (data.status === 200) {
                        app.overlay = false;
                        app.isExamSubmitted = true;

                    }

                });
            },
            sendTestToServer() {

                const app = this;

                app.overlay = true;
                app.dialog = true;
                app.startTimer = false;

                let arr = [];
                this.testQuestions.forEach(element => {
                    let ua = element.useranswered;
                    let st = element.status;
                    let obj = {};
                    if (ua >= 0) {
                        obj["ua"] = ua;
                        obj["st"] = st;
                        arr.push(obj);
                    } else {
                        obj["ua"] = 999;
                        if (st === undefined) {
                            obj["st"] = "";
                        } else {
                            obj["st"] = st;
                        }
                        arr.push(obj);
                    }
                });

                // console.log(arr);

                let options = {
                    testid: app.$route.params.id,
                    responses: arr,
                    uid: window.localStorage.getItem("uid")
                };

                axios
                    .post(APIService.getAPI_URL() + "test/responses", options, [
                        {withCredentials: true, crossdomain: true}
                    ])
                    .then(function (response) {
                        // eslint-disable-next-line no-console
                        console.log(response.data);
                        if (response.data.status === 200) {
                            app.overlay = false;
                        }
                    })
                    .catch(function (error) {
                        // eslint-disable-next-line no-console
                        console.log(error);
                    });
            },
            goHome() {
                // window.location = "/"
                this.$router.replace("/");
            },
            goResults() {
                if (this.isItDailyTest) {
                    this.$router.replace("/daily-tests");
                } else {
                    this.$router.replace("/weekly-tests");
                }
            },
            clearResponse() {
                this.radios = null;
                this.testQuestions[this.realQuestionNumber]["status"] = 1;
                this.testQuestions[this.realQuestionNumber]["useranswered"] = "";
            },
            getDailyTestQuestionsFromServer(testID) {

                const app = this;
                apiService.getDailyTestFromServer(testID).then(data => {
                    // eslint-disable-next-line no-console
                    // console.log(data.data.meta[0].time);
                    app.testQuestions = data.data.test;
                    app.min = data.data.meta[0].time;
                    app.numberofQuestions = data.data.test.length;
                    if (app.testQuestions[app.realQuestionNumber] !== undefined) {
                        // console.log("NOT UNDEFINED");
                        app.showThisQuestion();
                        app.startTimer = true;
                        app.showProgress = false;
                    } else {
                        // eslint-disable-next-line no-console
                        console.log("UNDEFINED");

                        app.currentQuestion = {
                            q: "Question in not available",
                            o1: "option 1 is Not Found",
                            o2: "option 2 is this",
                            o3: "option 3 is this",
                            o4: "option 4 is this",
                            id: "14456"
                        };
                    }

                });

            },
            getWeeklyTestQuestionsFromServer(testID) {

                const app = this;
                apiService.getWeeklyTestFromServer(testID).then(data => {
                    // eslint-disable-next-line no-console
                    // console.log(data.data.meta[0].time);
                    app.testQuestions = data.data.test;
                    // eslint-disable-next-line no-console
                    // console.log(app.testQuestions);

                    app.min = data.data.meta[0].time;
                    app.numberofQuestions = data.data.test.length;
                    if (app.testQuestions[app.realQuestionNumber] !== undefined) {
                        // console.log("NOT UNDEFINED");
                        app.showThisQuestion();
                        app.startTimer = true;
                        app.showProgress = false;
                    } else {
                        // eslint-disable-next-line no-console
                        // console.log("UNDEFINED");

                        app.currentQuestion = {
                            q: "Question in not available",
                            o1: "option 1 is Not Found",
                            o2: "option 2 is this",
                            o3: "option 3 is this",
                            o4: "option 4 is this",
                            id: "14456"
                        };
                    }

                });

            },

            // getQuestionsFromServer(testID) {
            //     let app = this;
            //     // eslint-disable-next-line no-console
            //     console.log("Gettingquestionsfromserver");
            //     APIService.getTodaysTest(testID).then(data => {
            //         // console.log(data);
            //         app.testQuestions = data.data;
            //
            //         // console.log(app.testQuestions);
            //
            //         app.numberofQuestions = data.data.length;
            //         if (app.testQuestions[app.realQuestionNumber] !== undefined) {
            //             // console.log("NOT UNDEFINED");
            //             app.showThisQuestion();
            //             app.startTimer = true;
            //             app.showProgress = false;
            //         } else {
            //             // eslint-disable-next-line no-console
            //             console.log("UNDEFINED");
            //
            //             app.currentQuestion = {
            //                 q: "Question in not available",
            //                 o1: "option 1 is Not Found",
            //                 o2: "option 2 is this",
            //                 o3: "option 3 is this",
            //                 o4: "option 4 is this",
            //                 id: "14456"
            //             };
            //             // return
            //
            //             // console.log("-------" + JSON.stringify(app.currentQuestion));
            //         }
            //     });
            // }
        },
        beforeRouteLeave(to, from, next) {
            if (!this.isExamSubmitted) {
                const answer = window.confirm('Do you really want to leave? you have unsaved changes!');
                if (answer) {
                    next()
                } else {
                    next(false)
                }
            } else {
                next()
            }

        }
    };
</script>

<!--suppress CssUnusedSymbol -->
<style scoped>
    >>> .sibling-2 {
        /* overflow: auto; */
        max-height: 48vh;
        overflow-y: auto;
    }

    >>> .flip-card {
        font-size: 2rem !important;
    }

    .fade-enter-active {
        transition: opacity 1.5s;
    }

    .fade-leave-active {
        opacity: 0;
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }

    .v-radio {
        height: auto;
    }

    >>> .v-label {
        width: 100% !important;
        height: auto !important;
    }
</style>
